<template>
  <div class="content">
    <PageHeader title="WhatsApp" :subtitle="$t('template-component.new')" />
    <section class="page-content container-fluid mb-3">
      <div class="row">
        <!-- Content col -->
        <div class="col-8 left-content-column">
          <h2>Categoria</h2>
          <span>Escolha uma categoria que melhor descreva seu modelo de mensagem</span>
          <div class="accordion accordion-wpp-template" role="tablist">
            <b-card no-body>
              <b-card-header header-tag="header" role="tab">
                <div class="btn-wrapper-opt">
                  <button @click="toggleAccordion('accordion-1'), changeCategory('MARKETING')"
                    class="btn btn-template stretched-link">
                    <span class="material-symbols-outlined">campaign</span>
                    <div class="wrapper-title">
                      <p class="title-opt">Marketing</p>
                      <span>Promoções ou informações sobre sua empresa, produtos ou serviços.
                        Ou qualquer mensagem que não seja relevante ou de
                        autenticação.</span>
                    </div>
                  </button>
                  <div class="control-group opt-2 toggle-check">
                    <label class="control control-checkbox">
                      <input id="accordion-1-input" class="wpp-input-opt" type="radio" name="wpp-opt-radio"
                        onclick="return false" checked />
                      <div class="control_indicator"></div>
                    </label>
                  </div>
                </div>
              </b-card-header>
            </b-card>
            <b-card no-body>
              <b-card-header header-tag="header" role="tab">
                <!-- <b-button block v-b-toggle.accordion-2 variant="info">Accordion 2</b-button> -->
                <div class="btn-wrapper-opt">
                  <button @click="toggleAccordion('accordion-2'), changeCategory('UTILITY')"
                    class="btn btn-template stretched-link">
                    <span class="material-symbols-outlined">notifications</span>
                    <div class="wrapper-title">
                      <p class="title-opt">Utilidade</p>
                      <span>Envie mensagens sobre uma conta ou pedido existente.</span>
                    </div>
                  </button>
                  <div class="control-group opt-2 toggle-check">
                    <label class="control control-checkbox">
                      <input id="accordion-2-input" class="wpp-input-opt" type="radio" name="wpp-opt-radio"
                        onclick="return false" />
                      <div class="control_indicator"></div>
                    </label>
                  </div>
                </div>
              </b-card-header>
            </b-card>
            <b-card no-body>
              <b-card-header header-tag="header" role="tab">
                <!-- <b-button block v-b-toggle.accordion-3 variant="info">Accordion 3</b-button> -->
                <div class="btn-wrapper-opt">
                  <button @click="
                    toggleAccordion('accordion-3'),
                    changeCategory('AUTHENTICATION'),
                    template.structure.type == 'AUTH'
                    " class="btn btn-template stretched-link">
                    <span class="material-symbols-outlined fill-icon">key</span>
                    <div class="wrapper-title">
                      <p class="title-opt">Autenticação</p>
                      <span>Senhas descartáveis que seus clientes usam para autenticar uma
                        transação ou login.</span>
                    </div>
                  </button>
                  <div class="control-group opt-2 toggle-check">
                    <label class="control control-checkbox">
                      <input id="accordion-3-input" class="wpp-input-opt" type="radio" name="wpp-opt-radio"
                        onclick="return false" />
                      <div class="control_indicator"></div>
                    </label>
                  </div>
                </div>
              </b-card-header>
              <b-collapse id="accordion-3" accordion="my-accordion" role="tabpanel">
                <!-- <b-card-body>
                  <b-card-text>{{ text }}</b-card-text>
                </b-card-body> -->
              </b-collapse>
            </b-card>
          </div>
          <h2 v-if="this.template.structure.category === 'MARKETING'">Tipo</h2>
          <span v-if="this.template.structure.category === 'MARKETING'">Escolha o tipo de template</span>
          <div class="accordion accordion-wpp-template d-flex type-row" role="tablist"
            v-if="this.template.structure.category === 'MARKETING'">
            <b-card no-body>
              <b-card-header header-tag="header" role="tab" :class="{ active: template.structure.type == 'DEFAULT' }">
                <!-- <b-button block v-b-toggle.accordion-1 variant="info">Accordion 1</b-button> -->
                <div class="btn-wrapper-opt">
                  <button @click="changeType('DEFAULT')" class="btn btn-template stretched-link">
                    <span class="material-symbols-outlined">apps</span>
                    <div class="wrapper-title">
                      <p class="title-opt">Padrão</p>
                    </div>
                  </button>
                </div>
              </b-card-header>
            </b-card>
            <b-card no-body>
              <b-card-header header-tag="header" role="tab" :class="{ active: template.structure.type == 'CAROUSEL' }">
                <!-- <b-button block v-b-toggle.accordion-2 variant="info">Accordion 2</b-button> -->
                <div class="btn-wrapper-opt">
                  <button @click="changeType('CAROUSEL')" class="btn btn-template stretched-link">
                    <span class="material-symbols-outlined">view_carousel</span>
                    <div class="wrapper-title">
                      <p class="title-opt">Carrossel</p>
                    </div>
                  </button>
                </div>
              </b-card-header>
            </b-card>
            <b-card no-body>
              <b-card-header header-tag="header" role="tab" :class="{ active: template.structure.type == 'CATALOG' }">
                <!-- <b-button block v-b-toggle.accordion-3 variant="info">Accordion 3</b-button> -->
                <div class="btn-wrapper-opt">
                  <button @click="changeType('CATALOG')" class="btn btn-template stretched-link">
                    <span class="material-symbols-outlined fill-icon">storefront</span>
                    <div class="wrapper-title">
                      <p class="title-opt">Catálogo</p>
                    </div>
                  </button>
                </div>
              </b-card-header>
            </b-card>
            <b-card no-body>
              <b-card-header header-tag="header" role="tab"
                :class="{ active: template.structure.type == 'COUPON_CODE' }">
                <!-- <b-button block v-b-toggle.accordion-3 variant="info">Accordion 3</b-button> -->
                <div class="btn-wrapper-opt">
                  <button @click="changeType('COUPON_CODE')" class="btn btn-template stretched-link">
                    <span class="material-symbols-outlined fill-icon">percent</span>
                    <div class="wrapper-title">
                      <p class="title-opt">Cupom</p>
                    </div>
                  </button>
                </div>
              </b-card-header>
            </b-card>
            <b-card no-body>
              <b-card-header header-tag="header" role="tab" :class="{ active: template.structure.type == 'OFFER' }">
                <!-- <b-button block v-b-toggle.accordion-3 variant="info">Accordion 3</b-button> -->
                <div class="btn-wrapper-opt">
                  <button @click="changeType('OFFER')" class="btn btn-template stretched-link">
                    <span class="material-symbols-outlined fill-icon">sell</span>
                    <div class="wrapper-title">
                      <p class="title-opt">Oferta</p>
                    </div>
                  </button>
                </div>
              </b-card-header>
            </b-card>
            <b-card no-body>
              <b-card-header header-tag="header" role="tab"
                :class="{ active: template.structure.type == 'MULT_PRODUCT' }">
                <!-- <b-button block v-b-toggle.accordion-3 variant="info">Accordion 3</b-button> -->
                <div class="btn-wrapper-opt">
                  <button @click="changeType('MULT_PRODUCT')" class="btn btn-template stretched-link">
                    <span class="material-symbols-outlined fill-icon">shopping_basket</span>
                    <div class="wrapper-title">
                      <p class="title-opt">Produtos</p>
                    </div>
                  </button>
                </div>
              </b-card-header>
            </b-card>
          </div>
          <div class="form-group mb-4" v-if="template.structure.type == 'CAROUSEL'">
            <div class="alert alert-warning black-text">
              O Conteúdo de cada card não pode ser repetido, caso contrário, o template
              poderá ser rejeitado pela meta.
            </div>
          </div>
          <div class="form-group mb-4">
            <label for="wpp-language">Telefone</label>
            <SelectComponent></SelectComponent>
            <select class="form-control" @change="changeNumber" v-model="whatsapp_identification">
              <option v-for="number in whatsNumbers" :key="number.id" :value="number.phone_number">
                {{ number.phone_number | phone }} ({{ number.name }})
              </option>
            </select>
          </div>
          <div class="form-group mb-4">
            <label for="wpp-language">Idioma</label>
            <select class="form-control" v-model="template.structure.language">
              <option value="pt_BR" selected>Português (BR)</option>
              <option value="pt_PT">Português (POR)</option>
              <option value="es">Espanhol</option>
              <option value="en_US">Inglês</option>
              <option value="fr">Francês</option>
              <option value="de">Alemão</option>
              <option value="zh_CN">Chinês</option>
            </select>
          </div>
          <h2 class="mb-4">Informações da Campanha</h2>
          <div class="form-group mb-4">
            <label for="wpp-name">{{ $tc("app.name", 1) }}:</label>
            <input id="wpp-name" class="form-control" :placeholder="$t('template-component.give-name')"
              v-model="template.structure.name" @input="fixTitleChars()" maxlength="512" />
            <small class="form-text text-muted">{{ template.structure.name.length }}/512</small>
          </div>
          <!-- Header -->
          <label class="mb-4" v-if="
            template.structure.category != 'AUTHENTICATION' &&
            (template.structure.type == 'DEFAULT' ||
              template.structure.type == 'MULT_PRODUCT' ||
              template.structure.type == 'COUPON_CODE')
          ">Header:</label>
          <div class="header-wrapper" v-if="
            template.structure.category != 'AUTHENTICATION' &&
            (template.structure.type == 'DEFAULT' ||
              template.structure.type == 'MULT_PRODUCT' ||
              template.structure.type == 'COUPON_CODE' ||
              template.structure.type == 'OFFER')
          ">
            <b-card no-body v-if="
              template.structure.type == 'DEFAULT' ||
              template.structure.type == 'COUPON_CODE' ||
              template.structure.type == 'MULT_PRODUCT' ||
              template.structure.type == 'OFFER'
            ">
              <b-card-header header-tag="header" :class="{ active: template.headerType == '' }">
                <span class="material-symbols-outlined" style="color: #ccc">close</span>
                <button class="btn btn-choose-type stretched-link"
                  @click="(template.headerType = ''), hideUploadFile()">
                  Nenhum
                </button>
              </b-card-header>
            </b-card>
            <b-card no-body v-if="
              template.structure.type == 'DEFAULT' ||
              template.structure.type == 'COUPON_CODE' ||
              template.structure.type == 'MULT_PRODUCT'
            ">
              <b-card-header header-tag="header" :class="{ active: template.headerType == 'TEXT' }">
                <img src="@/assets/skeleton-text.svg" alt="Skeleton Text" />
                <button class="btn btn-choose-type stretched-link"
                  @click="(template.headerType = 'TEXT'), hideUploadFile()">
                  Texto
                </button>
              </b-card-header>
            </b-card>
            <b-card no-body v-if="template.structure.type != 'MULT_PRODUCT'">
              <b-card-header header-tag="header" :class="{ active: template.headerType == 'IMAGE' }">
                <div class="icon-wrapper">
                  <span class="material-symbols-outlined">photo</span>
                </div>
                <button class="btn btn-choose-type stretched-link" @click="
                  showUploadFile(),
                  (template.headerType = 'IMAGE'),
                  (typeFile = 'image/*'),
                  (typeUpload = 'image')
                  ">
                  Imagem
                </button>
              </b-card-header>
            </b-card>
            <b-card no-body v-if="
              template.structure.type == 'DEFAULT' ||
              template.structure.type == 'COUPON_CODE' ||
              template.structure.type == 'OFFER'
            ">
              <b-card-header header-tag="header" :class="{ active: template.headerType == 'VIDEO' }">
                <div class="icon-wrapper">
                  <span class="material-symbols-outlined">video_library</span>
                </div>
                <div class="sr-only" v-if="template.headerType == 'VIDEO'">
                  <input type="file" ref="currentFile" accept="video/mp4" @change="getLocalFile('VIDEO')" />
                </div>
                <button class="btn btn-choose-type stretched-link" @click="
                  showUploadFile(),
                  (template.headerType = 'VIDEO'),
                  (typeFile = 'video/mp4'),
                  (typeUpload = 'video')
                  ">
                  Vídeo
                </button>
              </b-card-header>
            </b-card>
            <b-card no-body v-if="
              template.structure.type == 'DEFAULT' ||
              template.structure.type == 'COUPON_CODE'
            ">
              <b-card-header header-tag="header" :class="{ active: template.headerType == 'DOCUMENT' }">
                <div class="icon-wrapper">
                  <span class="material-symbols-outlined">description</span>
                </div>
                <div class="sr-only" v-if="template.headerType == 'DOCUMENT'">
                  <input type="file" ref="currentFile" accept="application/pdf" @change="getLocalFile('DOCUMENT')" />
                </div>
                <button class="btn btn-choose-type stretched-link" @click="
                  showUploadFile(),
                  (template.headerType = 'DOCUMENT'),
                  (typeFile = 'application/pdf'),
                  (typeUpload = 'document')
                  ">
                  Documento
                </button>
              </b-card-header>
            </b-card>
          </div>
          <div class="form-group mb-4" v-if="template.headerType == 'TEXT'">
            <label for="wpp-name">Texto do header:</label>
            <input id="wpp-name" class="form-control" v-model="template.headers.TEXT.body" @input="updateHeaderParams()"
              maxlength="60" />
            <small class="form-text text-muted">{{ template.headers.TEXT.body.length }}/60</small>
          </div>
          <!--/Header -->
          <div class="d-flex" style="margin-bottom: 20px" v-if="template.structure.type == 'OFFER'">
            <label style="width: 90%">Tem prazo de validade? </label>
            <div class="control-group opt-2 toggle-check" style="width: 10%">
              <label class="control control-checkbox">
                <input type="checkbox" name="notifications-opt" v-model="template.limited.has_expiration" />
                <div class="control_indicator" style="margin-bottom: 0"></div>
              </label>
            </div>
          </div>
          <div class="form-group mb-4" v-if="template.structure.type == 'OFFER'">
            <label for="wpp-name">Texto da oferta:</label>
            <input id="wpp-name" class="form-control" v-model="template.limited.text" maxlength="16" />
            <small class="form-text text-muted">{{ template.limited.text.length }}/16</small>
          </div>
          <!-- Body -->
          <div class="form-group mb-4">
            <label v-if="template.structure.category !== 'AUTHENTICATION'">Body: </label>
            <div class="form-group mb-4" v-if="template.structure.category == 'AUTHENTICATION'">
              <div class="d-flex" style="margin-bottom: 20px">
                <label style="width: 90%">Recomendação de segurança </label>
                <div class="control-group opt-2 toggle-check" style="width: 10%">
                  <label class="control control-checkbox">
                    <input type="checkbox" name="notifications-opt"
                      v-model="template.structure.body.add_security_recommendation" />
                    <div class="control_indicator" style="margin-bottom: 0"></div>
                  </label>
                </div>
              </div>
            </div>
            <whats-input v-if="template.structure.category !== 'AUTHENTICATION'" v-model="template.structure.body.text"
              :variables="vars" :paramsChange="updateBodyParams" />
            <div class="alert alert-danger" role="alert" v-if="!validBodyParams">
              <h4 class="alert-heading">Problemas nas variáveis</h4>
              <p>
                Existem problemas nas variáveis, recomendamos que
                <a class="text-light" target="_blank" rel="noopener noreferrer"
                  href="https://developers.facebook.com/docs/whatsapp/message-templates/guidelines/?locale=pt_BR">
                  leia mais sobre formatação em diretrizes de modelo de mensagem
                </a>
              </p>
            </div>
            <template v-else>
              <div class="row" v-if="
                template.structure.body.text &&
                template.structure.category !== 'AUTHENTICATION'
              ">
                <button @click="deleteParam" class="btn btn-primary ml-auto mr-3">
                  Limpar parametros
                </button>
              </div>
              <template v-for="(param, index) in template.structure.body.example_parameters">
                <p class="param-col" :key="index">
                  <label>Parâmetro {{ index + 1 }} de exemplo</label>
                  <input type="text" class="form-control" v-model="template.structure.body.example_parameters[index]" />
                </p>
              </template>
            </template>
          </div>
          <!--/Body -->
          <!-- Footer -->
          <div class="form-group mb-4" v-if="
            template.structure.category != 'AUTHENTICATION' &&
            template.structure.type != 'OFFER' &&
            template.structure.type != 'CAROUSEL'
          ">
            <label for="wpp-footer">Footer <span>(Opcional)</span></label>
            <input id="wpp-footer" class="form-control" placeholder="Parte inferior do seu modelo de mensagem."
              v-model="template.footer.body" maxlength="60" />
            <small class="form-text text-muted">{{ template.footer.body.length }}/60</small>
          </div>
          <div class="form-group mb-4" v-if="template.structure.category == 'AUTHENTICATION'">
            <label for="wpp-footer">Tempo e expiração em minutos<span>(Opcional)</span></label>
            <input id="wpp-footer" class="form-control" placeholder=""
              v-model="template.structure.footer.code_expiration_minutes" type="number" />
          </div>
          <div class="form-group mb-4" v-if="
            template.structure.category !== 'AUTHENTICATION' &&
            template.structure.type == 'COUPON_CODE'
          ">
            <template>
              <p>Preencha os dados do cupom</p>
              <div class="card card-btn-action">
                <div class="row card-body pb-0 mb-0 left-content-column">
                  <div class="col-md-12 form-group mb-4">
                    <label>Cupom</label>
                    <input type="text" class="form-control" maxlength="15"
                      v-model="template.call_to_copy_code.example_code" />
                    <small class="form-text text-muted">{{ template.call_to_copy_code.example_code.length }}/15</small>
                  </div>
                </div>
                <p></p>
              </div>
            </template>
          </div>
          <div class="form-group mb-4" v-if="template.structure.type == 'OFFER'">
            <template>
              <h4 class="mb-4">Url da oferta</h4>
              <div class="card-btn-action">
                <div class="row pb-0 mb-0 left-content-column">
                  <div class="col-md-12 form-group">
                    <label>Título da url</label>
                    <input type="text" class="form-control" maxlength="25"
                      v-model="template.call_to_action_website.text" />
                    <small class="form-text text-muted">{{ template.call_to_action_website.text.length }}/25</small>
                  </div>
                  <div class="col-md-12 m-top-10 form-group mb-4" style="margin-top: 10px; margin-bottom: 10px">
                    <label>URL</label>
                    <input type="text" class="form-control" maxlength="2000"
                      v-model="template.call_to_action_website.url" />
                    <small class="form-text text-muted">{{ template.call_to_action_website.text.length }}/2000</small>
                  </div>
                </div>
                <p></p>
              </div>
              <h4 class="mb-4">Cupom da oferta</h4>
              <div class="card-btn-action">
                <div class="row pb-0 mb-0 left-content-column">
                  <div class="col-md-12 form-group">
                    <label>Código do cupom</label>
                    <input type="text" class="form-control" maxlength="15"
                      v-model="template.call_to_copy_code.example_code" />
                    <small class="form-text text-muted">{{ template.call_to_copy_code.example_code.length }}/15</small>
                  </div>
                </div>
                <p></p>
              </div>
            </template>
          </div>
          <div class="form-group mb-4" v-if="
            template.structure.category != 'AUTHENTICATION' &&
            template.structure.type == 'DEFAULT'
          ">
            <label for="wpp-btn">Buttons <span>(Opcional)</span></label>
            <span class="d-block w-100 mb-1">
              Crie até 10 botões para interagir com seus clientes.
            </span>

            <div v-for="(button, index) in template.buttons.list" :key="index" class="card mb-3 card-buttons-template">
              <div class="card-header d-flex align-items-center text-align-center justify-content-between"
                @click="toggleCard(index)" :aria-expanded="button.isOpen" :aria-controls="'card-body-' + index"
                style="cursor: pointer">
                <div class="d-flex align-items-center">
                  <span>Botão {{ index + 1 }}</span>
                  <span v-if="button.isOpen" class="material-symbols-outlined">
                    keyboard_arrow_up
                  </span>
                  <span v-else class="material-symbols-outlined">
                    keyboard_arrow_down
                  </span>
                </div>
                <div>
                  <button class="btn btn-danger btn-sm float-right ml-2" @click.stop="removeButton(index)">
                    Remover
                  </button>
                </div>
              </div>
              <div class="card-body card-content-btn" v-if="button.isOpen" :id="'card-body-' + index">
                <select class="form-control mb-3" v-model="button.type" @change="resetButtonContent(button)">
                  <option disabled value="">Selecione o tipo</option>
                  <option value="QUICK_REPLY">Respostas rápidas</option>
                  <option value="CALL_TO_ACTION_PHONE">Celular (Call To Action)</option>
                  <option value="CALL_TO_ACTION_WEBSITE">URL (Call To Action)</option>
                </select>

                <!-- QUICK_REPLY -->
                <template v-if="button.type === 'QUICK_REPLY'">
                  <div class="mb-2">
                    <label>
                      Resposta rápida
                      <span class="badge badge-success">Obrigatório</span>
                    </label>
                    <input type="text" class="form-control" maxlength="25" v-model="button.quick_reply.text" />
                  </div>
                </template>

                <!-- CALL_TO_ACTION_PHONE -->
                <template v-if="button.type === 'CALL_TO_ACTION_PHONE'">
                  <p>Preencha telefone ou website ou ambos</p>
                  <div class="card card-btn-action2">
                    <div class="card-header">Chamar telefone</div>
                    <div class="row mb-3 card-body">
                      <div class="col-md-6">
                        <label>Texto do botão</label>
                        <input type="text" class="form-control mb-2" maxlength="25"
                          v-model="button.call_to_action_dial_phone.text" />
                      </div>
                      <div class="col-md-6">
                        <label>Telefone</label>
                        <vue-tel-input v-bind="dialPhoneProps" v-model="button.call_to_action_dial_phone.number" />
                      </div>
                    </div>
                  </div>
                </template>
                <template v-if="button.type === 'CALL_TO_ACTION_WEBSITE'">
                  <div class="card card-btn-action2">
                    <div class="card-header">Visitar website</div>
                    <div class="row card-body pb-0 mb-0">
                      <div class="col-md-6">
                        <label>Texto do botão</label>
                        <input type="text" class="form-control" maxlength="25"
                          v-model="button.call_to_action_website.text" />
                      </div>
                      <div class="col-md-6">
                        <label>Website</label>
                        <input type="text" class="form-control" maxlength="2000"
                          v-model="button.call_to_action_website.url" />
                      </div>
                      <div class="col-12">
                        <div v-if="hasLinkParam(button.call_to_action_website.url)" class="input-group my-4">
                          <div class="input-group-prepend">
                            <span class="input-group-text">Exemplo de parametro</span>
                          </div>
                          <input type="text" class="form-control" placeholder="Digite um exemplo para variável..."
                            v-model="button.call_to_action_website.example" />
                        </div>
                      </div>
                    </div>
                    <p class="mx-4 my-2">
                      Apenas uma variável
                      <code v-html="'{{1}}'"></code> pode ser adicionada ao final de uma
                      URL (<a class="text-light" target="_blank" rel="noopener noreferrer"
                        href="https://business.facebook.com/business/help/2055875911147364">Leia mais</a>), exemplos:
                    </p>

                    <div class="alert alert-primary mx-4 my-2" role="alert">
                      <pre class="mb-0"><code>https://meusite/?id=<span v-html="'{{1}}'"></span></code></pre>
                    </div>

                    <div class="alert alert-primary mx-4 my-2" role="alert">
                      <pre class="mb-0"><code>https://meusite/promocao/<span v-html="'{{1}}'"></span></code></pre>
                    </div>
                    <p></p>
                  </div>
                </template>
              </div>
            </div>

            <!-- Botão para adicionar mais -->
            <button class="btn btn-primary ml-auto mt-3" @click="addButton"
              :disabled="template.buttons.list.length >= 10">
              Adicionar botão
            </button>
            <div v-if="!validButtonConfig" class="alert alert-danger">
              <h4>Configuração Inválida</h4>
              <p>Verifique os botões configurados e corrija os problemas listados.</p>
            </div>
          </div>

          <!--/Footer -->
          <div class="btn-wrapper">
            <button class="btn btn-outline-primary" @click="$router.go(-1)">
              {{ $t("app.cancel") }}
            </button>
            <button @click="saveTemplate()" class="btn btn-yup-purple ml-auto" :disabled="isSending || !validTemplate"
              :class="{ 'qt-loader qt-loader-mini qt-loader-right': isSending }">
              {{ $t("app.save") }} template
            </button>
          </div>
        </div>
        <!--/Content col -->
        <!-- Preview -->
        <div class="col">
          <div class="preview-wrapper">
            <div class="card-preview" v-if="template.structure.type == 'AUTH'">
              <div class="card" style="width: 300px">
                <div class="card-body">
                  <p class="m-b-0">
                    {{ auth_messages[template.structure.language].message }}
                    <strong style="font-weight: 600">123</strong>
                  </p>
                  <p v-if="template.footer.code_expiration_minutes" class="minutes-info">
                    {{ auth_messages[template.structure.language].time }}
                    {{ template.footer.code_expiration_minutes }} minutos
                  </p>
                  <div class="card-text"><span class="time-span">10:20</span></div>
                  <div class="btn-whats-message">
                    <a><span class="material-symbols-outlined to-left">content_copy</span>
                      {{ auth_messages[template.structure.language].copy }}</a>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-preview" v-if="template.structure.type == 'DEFAULT'">
              <div class="card" style="width: 300px">
                <div class="card-body">
                  <header class="card-text" v-if="template.headerType === 'TEXT'">
                    <b>{{ template.headers.TEXT.body }}</b>
                  </header>
                  <header v-else-if="template.headerType === 'IMAGE'">
                    <div class="media">
                      <img :src="template.headerImage.url" class="image-default-header" v-if="template.headerImage" />
                    </div>
                  </header>
                  <header v-else-if="template.headerType === 'VIDEO'">
                    <div class="media">
                      <video :src="template.headerVideo.url" v-if="template.headerVideo" controls></video>
                    </div>
                  </header>
                  <header v-else-if="template.headerType === 'DOCUMENT'">
                    <div class="media">
                      <object type="application/pdf" :data="template.headerDocument.url"
                        v-if="template.headerDocument"></object>
                    </div>
                  </header>
                  <div class="card-text">
                    {{ template.structure.body.text }}
                    <span class="time-span">10:20</span>
                  </div>
                  <footer class="card-text footer-item" v-if="template.footer.body">
                    {{ template.footer.body }}
                  </footer>

                  <div v-for="(button, index) in template.buttons.list" :key="index">
                    <template v-if="button.type === 'QUICK_REPLY'">
                      <div class="btn-whats-message" v-if="button.quick_reply.text">
                        <a>
                          <span class="material-symbols-outlined to-left">reply</span>
                          {{ button.quick_reply.text }}
                        </a>
                      </div>
                    </template>
                    <template v-if="button.type === 'CALL_TO_ACTION_PHONE'">
                      <div class="btn-whats-message" v-if="button.call_to_action_dial_phone.number">
                        <a>
                          <span class="material-symbols-outlined to-left">phone</span>
                          {{ button.call_to_action_dial_phone.text }}
                        </a>
                      </div>
                    </template>
                    <template v-if="button.type === 'CALL_TO_ACTION_WEBSITE'">
                      <div class="btn-whats-message" v-if="button.call_to_action_website.url">
                        <a>
                          <span class="material-symbols-outlined to-left">open_in_new</span>
                          {{ button.call_to_action_website.text }}
                        </a>
                      </div>
                    </template>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-preview" v-if="template.structure.type == 'CAROUSEL'">
              <div class="card" style="margin-bottom: 10px">
                <div class="card-body">
                  <div class="card-text">
                    {{ template.structure.body.text }}
                    <span class="time-span">10:20</span>
                  </div>
                  <footer class="card-text footer-item" v-if="template.footer.body">
                    {{ template.footer.body }}
                  </footer>
                </div>
              </div>
              <div style="overflow-x: auto" class="d-flex">
                <div v-for="(card, n) in template.structure.carousel.content.cards" :key="n" class="col-8"
                  style="padding: 0px; padding-right: 10px">
                  <div class="card card-whats-message">
                    <div class="card-content">
                      <div class="card-img d-flex">
                        <img class="card-img-top img-fluid" style="height: 160px"
                          :src="card.components[0].example.header_handle[0]" alt="Card image cap" />
                        <div class="img-upload-icon">
                          <ImageUploader @upload="(file) => {
                              callUpload(file, card);
                            }
                            ">
                            <span class="material-icons card-upload-btn absolute" v-tooltip.top="'Atualizar foto'">
                              upload
                            </span>
                          </ImageUploader>
                        </div>
                        <div class="img-edit-icon" v-b-toggle.sidebar-right-card-body @click="cardEditing = card">
                          <span class="material-icons card-upload-btn absolute" v-tooltip.top="'Atualizar texto e url'">
                            edit_square
                          </span>
                        </div>
                        <a v-if="template.structure.carousel.content.cards.length > 2" role="button"
                          class="text-white absolute float-right close-cards" @click="removeCard(n)">
                          <span class="material-icons edit_square_icon icon-remove" v-tooltip.top="'Remover cartão'">
                            close
                          </span>
                        </a>
                      </div>
                      <div class="card-body">
                        <div class="card-text">{{ card.components[1].text }}</div>
                        <div class="btn-whats-message">
                          <a><span class="material-symbols-outlined to-left">open_in_new</span>
                            Web site</a>
                        </div>
                        <div class="btn-whats-message">
                          <a><span class="material-symbols-outlined to-left">reply</span>
                            Quick Replay</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-actions text-right">
                <div class="row">
                  <div class="col-md-12">
                    <button style="margin-top: 8px" class="btn btn-primary btn-rounded" @click="addCard">
                      Adicionar Card <i class="fas fa-plus"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-preview" v-if="template.structure.type == 'CATALOG'">
              <div style="overflow-x: auto" class="d-flex">
                <div class="col-9" style="padding: 0px; padding-right: 10px">
                  <div class="card card-whats-message">
                    <div class="card-content card-catalog">
                      <div class="card-img d-flex">
                        <div class="prod-preview">
                          <img class="card-img-top img-fluid" :src="template.structure.catalog.midia.url"
                            alt="Card image cap" />
                          <div>
                            <h3>Catálogo de exemplo</h3>
                            <p>Lorem Ipsum is simply dummy</p>
                          </div>
                        </div>
                      </div>
                      <div class="card-body">
                        <p class="card-text">
                          {{ template.structure.body.text }}
                          <span class="time-span">10:20</span>
                        </p>
                        <footer class="card-text footer-item" v-if="template.footer.body">
                          {{ template.footer.body }}
                        </footer>
                        <div class="btn-whats-message">
                          <a>Ver catálogo</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-preview" v-if="template.structure.type == 'MULT_PRODUCT'">
              <div style="overflow-x: auto" class="d-flex">
                <div class="col-9" style="padding: 0px; padding-right: 10px">
                  <div class="card card-whats-message">
                    <div class="card-content card-catalog">
                      <div class="card-img d-flex">
                        <div class="prod-preview">
                          <img class="card-img-top img-fluid" :src="template.headerImage.url" alt="Card image cap"
                            style="width: 70px; height: 50px; padding: 0px !important" />
                          <div>
                            <h3>{{ template.headers.TEXT.body }}</h3>
                            <p>10 itens</p>
                          </div>
                        </div>
                      </div>
                      <div class="card-body">
                        <p class="card-text">
                          {{ template.structure.body.text }}
                          <span class="time-span">10:20</span>
                        </p>
                        <footer class="card-text footer-item" v-if="template.footer.body">
                          {{ template.footer.body }}
                        </footer>
                        <div class="btn-whats-message">
                          <a>Ver produtos</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-preview" v-if="template.structure.type == 'OFFER'">
              <div style="overflow-x: auto" class="d-flex">
                <div class="col-9" style="padding: 0px; padding-right: 10px">
                  <div class="card card-whats-message">
                    <div class="card-content card-catalog">
                      <div class="card-img d-flex">
                        <img v-if="template.headerType === 'IMAGE'" class="card-img-top img-fluid h-160"
                          :src="template.headerImage.url" alt="Card image cap" />
                        <header v-else>
                          <div class="media">
                            <video class="video-header" :src="template.headerVideo.url" v-if="template.headerVideo"
                              controls></video>
                          </div>
                        </header>
                      </div>
                      <div class="card-img d-flex">
                        <div class="prod-preview">
                          <div class="offer-icon">
                            <span class="material-symbols-outlined"> redeem </span>
                          </div>
                          <div>
                            <h3>{{ template.limited.text }}</h3>
                            <p>Offer end on Apr 23</p>
                            <p class="offer-code">Code YUP50</p>
                          </div>
                        </div>
                      </div>
                      <div class="card-body">
                        <p class="card-text">
                          {{ template.structure.body.text }}
                          <span class="time-span">10:20</span>
                        </p>
                        <footer class="card-text footer-item" v-if="template.footer.body">
                          {{ template.footer.body }}
                        </footer>
                        <div class="btn-whats-message">
                          <a><span class="material-symbols-outlined to-left">content_copy</span>
                            Copiar código</a>
                        </div>
                        <div class="btn-whats-message">
                          <a><span class="material-symbols-outlined to-left">open_in_new</span>
                            Acessar loja</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-preview" v-if="template.structure.type == 'COUPON_CODE'">
              <div style="overflow-x: auto" class="d-flex">
                <div class="col-9" style="padding: 0px; padding-right: 10px">
                  <div class="card card-whats-message">
                    <div class="card-content card-catalog">
                      <div class="card-body">
                        <header class="card-text padding-b-0" v-if="template.headerType === 'TEXT'">
                          <b>{{ template.headers.TEXT.body }}</b>
                        </header>
                        <header v-else-if="template.headerType === 'IMAGE'">
                          <div class="media">
                            <img :src="template.headerImage.url" v-if="template.headerImage" />
                          </div>
                        </header>
                        <header v-else-if="template.headerType === 'VIDEO'">
                          <div class="media">
                            <video :src="template.headerVideo.url" v-if="template.headerVideo" controls></video>
                          </div>
                        </header>
                        <header v-else-if="template.headerType === 'DOCUMENT'">
                          <div class="media">
                            <object type="application/pdf" :data="template.headerDocument.url"
                              v-if="template.headerDocument"></object>
                          </div>
                        </header>
                        <p class="card-text">
                          {{ template.structure.body.text }}
                          <span class="time-span">10:20</span>
                        </p>
                        <footer class="card-text footer-item padding-t-0" v-if="template.footer.body">
                          {{ template.footer.body }}
                        </footer>
                        <div class="btn-whats-message btn-whats-message-coupon">
                          <a><span class="material-symbols-outlined to-left">content_copy</span>
                            {{ template.call_to_copy_code.text }}</a>
                        </div>
                        <div class="btn-whats-message btn-whats-message-coupon" style="border: none; display: none">
                          <a><span class="material-symbols-outlined to-left">open_in_new</span>
                            Acessar loja</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <img class="phone-img" src="@/assets/phone.svg" alt="Phone Background" />
          </div>
        </div>
        <!--/Preview -->
      </div>
    </section>
    <b-sidebar class="b-sidebar-modal" id="sidebar-right-card-body" :title="'Editar'" no-header right backdrop shadow>
      <div class="col sidebar-header">
        <span @click="cardEditing = {}" v-b-toggle.sidebar-right-card-body
          class="material-symbols-outlined sidebar-close c-pointer">close</span>
      </div>
      <div class="col desc-column"></div>
      <div class="add-prod-form col mb-0">
        <!-- Body -->
        <div class="form-group mb-4" v-if="cardEditing.components">
          <label>Body: </label>
          <whats-input :classText="'whats-input-text-area-card'" :maxLength="160"
            v-model="cardEditing.components[1].text" :variables="vars" :paramsChange="updateBodyParamsCard" />
          <div class="alert alert-danger" role="alert" v-if="!validBodyParamsCard">
            <h4 class="alert-heading">Problemas nas variáveis</h4>
            <p>
              Existem problemas nas variáveis, recomendamos que
              <a class="text-light" target="_blank" rel="noopener noreferrer"
                href="https://developers.facebook.com/docs/whatsapp/message-templates/guidelines/?locale=pt_BR">
                leia mais sobre formatação em diretrizes de modelo de mensagem
              </a>
            </p>
          </div>
          <template v-else>
            <div class="row" v-if="cardEditing.components[1].text.length">
              <button type="button" @click="deleteParam" class="btn btn-primary ml-auto mr-3">
                Limpar parametros
              </button>
            </div>
            <template v-for="(param, index) in cardEditing.components[1].example.body_text[0]">
              <p class="param-col" :key="index">
                <label>Parâmetro {{ index + 1 }} de exemplo</label>
                <input type="text" class="form-control"
                  v-model="cardEditing.components[1].example.body_text[0][index]" />
              </p>
            </template>
          </template>
          <div class="row left-content-column">
            <div class="col-md-12">
              <div class="form-group mb-4">
                <label>Url do botão</label>
                <input type="text" class="form-control" maxlength="2000"
                  v-model="cardEditing.components[2].buttons[1].url" />
                <small class="form-text text-muted">{{ cardEditing.components[2].buttons[1].url.length }}/2000</small>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <hr />
              <span @click="
                $root.$emit('bv::toggle::collapse', 'sidebar-right-card-body'),
                (cardEditing = {})
                " v-b-toggle.sidebar-right-card-body style="margin-top: 8px" class="btn btn-primary btn-rounded w-100">
                Salvar
              </span>
            </div>
          </div>
        </div>
        <!--/Body -->
      </div>
    </b-sidebar>
    <create-template-modal id="create-template-modal" />
    <send-template-modal id="send-template-modal" />
    <preview-modal id="preview-modal" />
    <m-crop id="m-crop" />
    <UploadComponent :preview="true" ref="uploadMenu" :accept="typeFile" :maxFileSize="5" @uploaded="uploaded" />
  </div>
</template>

<script>
// @ is an alias to /src
import 'bootstrap-vue/dist/bootstrap-vue.css';
import PageHeader from '@/components/rebranding/PageHeader.vue';
import CreateTemplateModal from '@/components/whatsapp/CreateTemplateModal.vue';
import SendTemplateModal from '@/components/whatsapp/SendTemplateModal.vue';
import PreviewModal from '@/components/whatsapp/PreviewModal.vue';
import WhatsInput from '@/components/rebranding/whatsapp/WhatsInput.vue';
import WhatsAppService from '@/services/whatsapp.service';
import MCrop from '@/components/MCrop.vue';
import BucketService from '@/services/bucket.service';
import axios from '@/plugins/axios';
import { VueTelInput } from 'vue-tel-input';
import ImageUploader from '@/components/ImageUploader.vue';
import UploadComponent from '@/components/ui/UploadComponent/Index.vue';
import { number } from 'echarts';

const webApi = axios();

export default {
  name: 'WhatsAppTemplates',
  components: {
    PageHeader,
    CreateTemplateModal,
    PreviewModal,
    SendTemplateModal,
    WhatsInput,
    MCrop,
    VueTelInput,
    ImageUploader,
    UploadComponent,
  },
  watch: {
    template: {
      handler(newValue, oldValue) {
        setTimeout(() => this.updateBodyParams(), 100);
      },
      deep: true,
    },
  },
  computed: {
    validTemplate() {
      if (!this.validBody) {
        console.warn('Template: Invalid body');
        return false;
      }

      const template = this.template;

      if (!/^[A-Za-z0-9_]+$/.test(template.structure.name)) {
        console.warn('Template: Invalid template name', template.structure.name);
        return false;
      }

      if (this.headerType === 'TEXT') {
        if (!this.validHeader) {
          console.warn('Template: Invalid header (TEXT)');
          return false;
        }
      }

      const btnType = template.buttons.type;

      if (btnType === 'QUICK_REPLY') {
        if (!this.template.buttons.list.quick_reply.text) {
          console.warn('Template: Invalid quick_replies');
          return false;
        }
      } else if (btnType === 'CALL_TO_ACTION') {
        const dial = template.buttons.call_to_action_dial_phone;
        const web = template.buttons.call_to_action_website;

        if (dial.number || dial.text) {
          if (
            !dial.number ||
            !dial.text ||
            !/^\d{8,}$/.test(dial.number.replace(/\D+/g, ''))
          ) {
            console.warn('Template: Invalid call_to_action_dial_phone', dial);
            return false;
          }
        }

        if (web.number || web.text) {
          if (!web.url || !web.text || !/https?:\/\/\S{5,}$/.test(web.url)) {
            console.warn('Template: Invalid call_to_action_website', web);
            return false;
          }
        }
      }

      return true;
    },
    validHeader() {
      if (this.headerType === 'TEXT') {
        const header = this.template.headers.TEXT.body;

        if (header.length > 60) return false;

        const params = [...header.matchAll(/\{\{([^\s]+?)\}\}/g)];

        if (params.length === 0) return true;

        return params.length > 1 || params[0][1] !== '{{1}}';
      }

      return true;
    },
    hasHeaderParam() {
      return /\{\{([^\s]+?)\}\}/.test(this.template.headers.TEXT.body);
    },
    headerParamMaxLength() {
      const header = this.template.headers.TEXT.body.replace(/\{\{1\}\}/, '');
      const param = this.template.headers.TEXT;

      const max = 60 - header.length;

      if (param.example_parameter) {
        param.example_parameter = param.example_parameter.substring(0, max);
      }

      return max;
    },
    validBodyParams() {
      if (
        this.cardEditing.components === undefined &&
        this.template.structure.body.text
      ) {
        const body = this.template.structure.body.text.trim();

        const begin = /([.!?{}]+)?\{\{[^\s]+?\}\}(\s+)$/;
        const ends = /\{\{[^\s]+?\}\}(\s+)?([.!?]+)?$/;
        const sequence = /\{\{[^\s]+?\}\}(\s+?)\{\{[^\s]+?\}\}/;

        if (begin.test(body) || ends.test(body) || sequence.test(body)) {
          return false;
        }

        const params = [...body.matchAll(/\{\{([^\s]+?)\}\}/g)];

        for (let i = 0, j = params.length; i < j; i += 1) {
          const param = parseInt(params[i][1], 10);
          if (!Number.isInteger(param)) return false;
        }
      }

      return true;
    },
    validBodyParamsCard() {
      if (this.cardEditing.components !== undefined) {
        const body = this.cardEditing.components[1].text.trim();

        const begin = /([.!?{}]+)?\{\{[^\s]+?\}\}(\s+)$/;
        const ends = /\{\{[^\s]+?\}\}(\s+)?([.!?]+)?$/;
        const sequence = /\{\{[^\s]+?\}\}(\s+?)\{\{[^\s]+?\}\}/;

        if (begin.test(body) || ends.test(body) || sequence.test(body)) {
          return false;
        }

        const params = [...body.matchAll(/\{\{([^\s]+?)\}\}/g)];

        for (let i = 0, j = params.length; i < j; i += 1) {
          const param = parseInt(params[i][1], 10);
          if (!Number.isInteger(param)) return false;
        }
      }

      return true;
    },
    validBody() {
      const body = this.template.structure.body.text;

      // console.log('validBody', body, body.length);
      if (body !== undefined) {
        return body.length <= 1028 && body.trim() !== '' && this.validBodyParams;
      }
      return true;
    },
  },
  data() {
    return {
      auth_messages: {
        pt_BR: {
          message: 'Seu código de verificação é',
          time: 'Esse código expira em',
          copy: 'Copiar código',
        },
        pt_PT: {
          message: 'Seu código de verificação é',
          time: 'Esse código expira em',
          copy: 'Copiar código',
        },
        es: {
          message: 'Tu código de verificación es',
          time: 'Este código caduca en',
          copy: 'Copiar código',
        },
        fr: {
          message: 'Votre code de vérification est',
          time: 'Ce code expire dans',
          copy: 'Copier le code',
        },
        en_US: {
          message: 'Your verification code is',
          time: 'This code expires in',
          copy: 'Copy code',
        },
        de: {
          message: 'Ihr Bestätigungscode lautet',
          time: 'Dieser Code läuft ab',
          copy: 'Code kopieren',
        },
        zh_CN: {
          message: '您的驗證碼是',
          time: '該代碼的有效期限為',
          copy: '複製程式碼',
        },
      },
      typeFile: 'image/*',
      business_identification: '',
      whatsNumbers: [],
      uploadProgress: 0,
      form: {},
      isSending: false,
      fetched: false,
      client: {},
      defaultBg: '/assets/img/image_preview_white.png',
      defaultBgPdf: '/assets/img/image_preview_white.png',
      cropActive: null,
      cropPreview: null,
      hidePreview: false,
      vars: [
        { name: this.$t('generic-str.lbl-first-name'), value: 'first_name' },
        { name: this.$t('generic-str.lbl-last-name'), value: 'last_name' },
        { name: this.$t('generic-str.lbl-cellphone'), value: 'mobile_number' },
        { name: 'Email', value: 'email' },
      ],
      file: null,
      testTemplate: null,
      cardEditing: {},
      typeUpload: '',
      template: {
        structure: {
          business_id: '',
          category: 'MARKETING',
          type: 'DEFAULT',
          name: '',
          language: 'pt_BR',
          // identification: '',
          header: null,
          body: {
            example_parameters: [],
            text: 'Summer is here, and weve got the freshest produce around!',
          },
          footer: null,
          buttons: null,
          carousel: {
            content: {
              type: 'CAROUSEL',
              cards: [
                {
                  components: [
                    {
                      type: 'HEADER',
                      format: 'IMAGE',
                      example: {
                        header_handle: [
                          'https://media.istockphoto.com/id/906808234/photo/handsome-man.jpg?s=612x612&w=0&k=20&c=Ec8IY-ETslaS56vmO77BJyEOpPM1hzJlLbs6xeKRoAc=',
                        ],
                      },
                    },
                    {
                      type: 'BODY',
                      text:
                        'Test Lorem Ipsum is simply dummy text of template whatsapp quick',
                      example: {
                        body_text: [[]],
                      },
                    },
                    {
                      type: 'BUTTONS',
                      buttons: [
                        {
                          type: 'QUICK_REPLY',
                          text: 'Send more like this',
                        },
                        {
                          type: 'URL',
                          text: 'Buy now',
                          url: 'https://yup.chat',
                          example: ['https://yup.chat'],
                        },
                      ],
                    },
                  ],
                },
                {
                  components: [
                    {
                      type: 'HEADER',
                      format: 'IMAGE',
                      example: {
                        header_handle: [
                          'https://framerusercontent.com/images/mm88aPJitnYketinmZeUGmbZCtA.jpg?scale-down-to=2048',
                        ],
                      },
                    },
                    {
                      type: 'BODY',
                      text:
                        'Yup Chat, única plataforma para todos os seus canais de comunicação',
                      example: {
                        body_text: [[]],
                      },
                    },
                    {
                      type: 'BUTTONS',
                      buttons: [
                        {
                          type: 'QUICK_REPLY',
                          text: 'Send more like this',
                        },
                        {
                          type: 'URL',
                          text: 'Buy now',
                          url: 'https://google.com',
                          example: ['https://google.com'],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          },
          catalog: {
            midia: {
              url: 'https://framerusercontent.com/images/45uA4nlH9K2klcAVTjkwsps0A0I.png',
            },
            components: [
              {
                type: 'BODY',
                text: '',
              },
              {
                type: 'FOOTER',
                text: '',
              },
              {
                type: 'BUTTONS',
                buttons: [
                  {
                    type: 'CATALOG',
                    text: 'View catalog',
                  },
                ],
              },
            ],
          },
          coupon_code: {
            components: [
              {
                type: 'BUTTONS',
                buttons: [
                  {
                    type: 'COPY_CODE',
                    example: '<EXAMPLE>',
                  },
                ],
              },
            ],
          },
        },
        headerType: '',
        headers: {
          TEXT: {
            body: '',
            example_parameter: null,
            type: 'TEXT',
          },
          IMAGE: {
            body: '',
            example_parameter: null,
            type: 'IMAGE',
          },
          VIDEO: {
            body: '',
            example_parameter: null,
            type: 'VIDEO',
          },
          DOCUMENT: {
            body: '',
            example_parameter: null,
            type: 'DOCUMENT',
          },
        },
        headerImage: null,
        headerVideo: null,
        headerDocument: null,
        footer: {
          body: '',
        },
        buttons: {
          type: '',
          list: [],
        },
        call_to_action_dial_phone: {
          number: '',
          text: '',
        },
        call_to_action_website: {
          example: '',
          text: '',
          url: '',
        },
        call_to_copy_code: {
          example_code: '',
          text: 'Copiar código da oferta',
        },
      },
      buttonErrorMessage: '',
      lastBody: null,
      lastBodyCard: null,
      dialPhoneProps: {
        mode: 'international',
        defaultCountry: 'BR',
        disabledFetchingCountry: false,
        disabled: false,
        disabledFormatting: true,
        placeholder: this.$t('generic-str.lbl-cellphone'),
        required: true,
        enabledCountryCode: false,
        enabledFlags: true,
        validCharactersOnly: true,
        preferredCountries: ['AU', 'BR'],
        onlyCountries: [],
        ignoredCountries: [],
        autocomplete: 'off',
        name: 'telephone',
        maxLen: 25,
        wrapperClasses: '',
        inputClasses: 'form-control',
        dropdownOptions: {
          disabledDialCode: false,
        },
        inputOptions: {
          showDialCode: false,
        },
      },
    };
  },
  created() {
    this.template.category = 'MARKETING';
    this.$root.$on('call.crop', this.crop);
    this.getWhatsNumbers();
  },
  methods: {
    callUploadFile() {
      this.$refs.uploadMenu.toggle();
    },
    hideUploadFile() {
      this.$refs.uploadMenu.hide();
    },
    showUploadFile() {
      this.$refs.uploadMenu.show();
    },
    uploaded(response) {
      switch (this.typeUpload) {
        case 'image':
          this.template.headers.IMAGE.example_parameter = response.data.path;
          this.template.headerImage = {
            url: response.data.path,
          };
          break;
        case 'video':
          this.template.headers.VIDEO.example_parameter = response.data.path;
          this.template.headerVideo = {
            url: response.data.path,
          };
          break;
        case 'document':
          this.template.headers.DOCUMENT.example_parameter = response.data.path;
          this.template.headerDocument = {
            url: response.data.path,
          };
          break;
        default:
          break;
      }
      this.$refs.uploadMenu.toggle();
    },
    removeCard(i) {
      this.template.structure.carousel.content.cards.splice(i, 1);
    },
    callUpload(file, card) {
      console.log(file);
      card.components[0].example.header_handle[0] = file.url;
      this.$forceUpdate();
    },
    addCard() {
      if (this.template.structure.carousel.content.cards.length < 10) {
        this.template.structure.carousel.content.cards.push({
          components: [
            {
              type: 'HEADER',
              format: 'IMAGE',
              example: {
                header_handle: [
                  'https://t3.ftcdn.net/jpg/06/46/00/00/360_F_646000029_JTtTlIY4MgOhl6U36hjDFsRjzMkRw9d1.jpg',
                ],
              },
            },
            {
              type: 'BODY',
              text:
                'Lorem Ipsum is simply dummy text of the printing and typesetting industry',
              example: {
                body_text: [[]],
              },
            },
            {
              type: 'BUTTONS',
              buttons: [
                {
                  type: 'QUICK_REPLY',
                  text: 'Send more like this',
                },
                {
                  type: 'URL',
                  text: 'Buy now',
                  url: 'https://google.com',
                  example: ['https://google.com'],
                },
              ],
            },
          ],
        });
      } else {
        this.$toast.show({
          title: 'Erro',
          content: 'Você atingiu o limite máximo de cartões',
          type: 'danger',
        });
      }
    },
    toggleAccordion(id) {
      const input = document.getElementById(`${id}-input`);
      // const checkboxInputs = document.querySelectorAll('.accordion .wpp-input-opt');
      this.$root.$emit('bv::toggle::collapse', id);
      if (input.checked === false) {
        /* [...checkboxInputs].forEach((checkboxInput) => {
          checkboxInput.checked = false;
        }); */
        document.getElementById(`${id}-input`).checked = true;
      } else {
        document.getElementById(`${id}-input`).checked = false;
      }
    },
    toggleAccordionType(id) {
      const input = document.getElementById(`${id}-input-type`);
      this.$root.$emit('bv::toggle::collapse', id);
      console.log(id);
      if (input.checked === false) {
        document.getElementById(`${id}-input-type`).checked = true;
      } else {
        document.getElementById(`${id}-input-type`).checked = false;
      }
    },
    changeType(type) {
      this.template.structure.type = type;
      switch (type) {
        case 'MULT_PRODUCT':
          this.template.headerImage = {
            url: 'https://framerusercontent.com/images/45uA4nlH9K2klcAVTjkwsps0A0I.png',
          };
          break;
        case 'OFFER':
          this.template.headerImage = {
            url: 'https://framerusercontent.com/images/45uA4nlH9K2klcAVTjkwsps0A0I.png',
          };
          this.template.limited = {
            text: 'Expiring offer!',
            has_expiration: false,
          };
          this.template.call_to_copy_code = {
            example_code: 'YUP50',
            text: 'Copiar código da oferta',
          };
          break;
        case 'CATALOG':
          this.template.structure.category = 'MARKETING';
          break;
        case 'COUPON_CODE':
          this.template.call_to_copy_code = {
            example_code: '',
            text: 'Copiar código da oferta',
          };
          break;
        default:
          break;
      }
    },
    changeCategory(category) {
      if (category === 'UTILITY') {
        this.template.structure.type = 'DEFAULT';
      }
      if (category === 'AUTHENTICATION') {
        this.template.structure.type = 'AUTH';
        this.template.structure.buttons = {
          type: 'OTP',
        };
        this.template.structure.footer = {
          code_expiration_minutes: 0,
        };
        this.template.structure.body = {
          add_security_recommendation: false,
        };
      }
      /* if (category === 'UTILITY') {
        document.querySelectorAll('.carousel-inner .btn').forEach((el) => {
          el.innerHTML = `${this.$t('app.select')}`;
          el.classList.remove('btn-yup-purple-outline');
          el.classList.add('btn-yup-purple');
        });
        document
          .getElementById('btn_transactional')
          .classList.add('btn-yup-purple-outline');
        document.getElementById('btn_transactional').innerHTML = `${this.$tc(
          'app.selected', 1,
        )}`;
      } else if (category === 'MARKETING') {
        document.querySelectorAll('.carousel-inner .btn').forEach((el) => {
          el.innerHTML = `${this.$t('app.select')}`;
          el.classList.remove('btn-yup-purple-outline');
          el.classList.add('btn-yup-purple');
        });
        document
          .getElementById('btn_marketing')
          .classList.add('btn-yup-purple-outline');
        document.getElementById('btn_marketing').innerHTML = `${this.$tc(
          'app.selected', 1,
        )}`;
      } else {
        document.querySelectorAll('.carousel-inner .btn').forEach((el) => {
          el.innerHTML = `${this.$t('app.select')}`;
          el.classList.remove('btn-yup-purple-outline');
          el.classList.add('btn-yup-purple');
        });
        document
          .getElementById('btn_disposable_pass')
          .classList.add('btn-yup-purple-outline');
        document.getElementById('btn_disposable_pass').innerHTML = `${this.$tc(
          'app.selected', 1,
        )}`;
      } */
      this.template.structure.category = category;
      // this.template.structure.category = category;
      /* if (this.template.structure.category.length < 1) {
        this.template.structure.category = category;
      } else {
        this.template.structure.category = '';
      } */
    },
    deleteParam(e) {
      /* const regex = new RegExp('{{(.*?)}}', 'g');
      this.template.structure.body.example_parameters = this.template.structure.body.example_parameters.replace(regex, ''); */
      this.template.structure.body.example_parameters = [];
      this.template.structure.body.text = '';
      document.querySelectorAll('.param-col').forEach((el) => el.remove());
    },
    fixTitleChars() {
      const structure = this.template.structure;
      structure.name = structure.name
        .replace(/\s+/g, '_')
        .replace(/[^A-Za-z\d_]+/g, '')
        .toLowerCase();
    },
    addButton() {
      if (!this.validButtonConfig) {
        this.$toast.show({
          title: 'Erro',
          content: 'Você atingiu o limite máximo de 10 botões',
          type: 'danger',
        });
        return;
      }

      this.template.buttons.list.push({
        quick_reply: { text: '' },
        call_to_action_dial_phone: { number: '', text: '' },
        call_to_action_website: { url: '', text: '', example: '' },
        type: '',
        isOpen: true,
      });
    },
    toggleCard(index) {
      this.template.buttons.list[index].isOpen = !this.template.buttons.list[index]
        .isOpen;
    },
    removeButton(index) {
      this.template.buttons.list.splice(index, 1);
    },
    resetButtonContent(button) {
      button.quick_reply = { text: '' };
      button.call_to_action_dial_phone = { number: '', text: '' };
      button.call_to_action_website = { url: '', text: '', example: '' };
    },
    formattedButtons() {
      const buttons = this.template.buttons.list
        .map((button) => {
          if (button.type === 'QUICK_REPLY') {
            return {
              type: 'QUICK_REPLY',
              text: button.quick_reply.text,
            };
          } if (button.type === 'CALL_TO_ACTION_PHONE') {
            return {
              type: 'PHONE_NUMBER',
              text: button.call_to_action_dial_phone.text,
              phone_number: button.call_to_action_dial_phone.number,
            };
          } if (button.type === 'CALL_TO_ACTION_WEBSITE') {
            const formattedButton = {
              type: 'URL',
              text: button.call_to_action_website.text,
              url: button.call_to_action_website.url,
            };

            if (button.call_to_action_website.example) {
              formattedButton.example = button.call_to_action_website.example;
            }

            return formattedButton;
          }
          return null;
        })
        .filter(Boolean);

      const quickReplies = buttons.filter((btn) => btn.type === 'QUICK_REPLY');
      const phoneButtons = buttons.filter((btn) => btn.type === 'PHONE_NUMBER');
      const urlButtons = buttons.filter((btn) => btn.type === 'URL');

      const orderedButtons = [...quickReplies, ...urlButtons, ...phoneButtons];

      return orderedButtons;
    },
    hasLinkParam(url) {
      return (
        [
          ...url.matchAll(
            /\{\{([^\s]+?)\}\}/g,
          ),
        ].length === 1
      );
    },
    chooseImage(maxWidth, maxHeight) {
      this.$root.$emit('show.modal', 'm-crop');
      this.$store.dispatch('crop/config', {
        maxWidth,
        maxHeight,
        viewMode: 1,
        minSize: true,
        infoSize: '1080x566',
      });
    },
    crop(data) {
      this.$root.$emit('show.modal', 'request-modal');
      this.template.headerImage = data;
      console.log('crop:', this.template);
    },

    chooseFile() {
      setTimeout(() => {
        this.$refs.currentFile.click();
      }, 100);
    },

    getLocalFile(type) {
      console.log('getLocalFile:', this.$refs.currentFile);

      const input = this.$refs.currentFile;

      if (!input) return;

      const file = input.files[0];

      if (type === 'VIDEO') {
        this.template.headerVideo = {
          file,
          url: URL.createObjectURL(file),
        };
      } else if (type === 'DOCUMENT') {
        this.template.headerDocument = {
          file,
          url: URL.createObjectURL(file),
        };
      }
    },

    // Upload real
    upload(type) {
      const header = this.template.headers[type];
      const form = new FormData();

      let source;

      if (type === 'IMAGE') {
        source = this.template.headerImage;
      } else if (type === 'VIDEO') {
        source = this.template.headerVideo;
      } else {
        source = this.template.headerDocument;
      }

      form.append('files[]', source.file);
      form.append('role', 'public');
      form.append('folder', 'whatsapp');
      form.append('account_id', this.$store.state.account.id);

      if (type === 'IMAGE') {
        const crop = JSON.stringify([
          {
            x: source.x,
            y: source.y,
            w: source.width,
            h: source.height,
          },
        ]);

        form.append('crop', crop);
      }

      BucketService.upload(form, true, (progress) => {
        this.uploadProgress = progress;
      })
        .then((responses) => {
          console.log(responses);
          const url = responses.data[0].url;
          console.log('BucketService', url);
          header.example_parameter = url;
          this.sendRequest();
        })
        .catch((error) => {
          this.content = error;
          this.isSending = false;
          console.error(error);
        })
        .finally(() => {
          this.isSending = false;
        });
    },
    validButtonConfig() {
      const totalButtonsValid = this.template.buttons.list.length <= 10;

      const isValid = this.template.buttons.list.every((button) => {
        if (button.type === 'QUICK_REPLY') {
          return button.quick_reply && button.quick_reply.text;
        }
        if (button.type === 'CALL_TO_ACTION_PHONE') {
          return button.call_to_action_dial_phone &&
            button.call_to_action_dial_phone.number &&
            button.call_to_action_dial_phone.text;
        }
        return true;
      });

      return isValid && totalButtonsValid;
    },
    saveTemplate() {
      const category = this.template.structure.category;
      console.log('Categoria:', category);

      if (!['AUTHENTICATION', 'MARKETING', 'UTILITY'].includes(category)) {
        this.$toast.show({
          title: 'Erro',
          content: 'Selecione uma categoria de template',
          type: 'danger',
        });
        console.log('Categoria inválida');
        return;
      }

      if (!this.validButtonConfig()) {
        console.log('Validação de botões falhou');
        return;
      }

      this.isSending = true;

      const formattedButtons = this.formattedButtons();

      console.log(JSON.stringify({
        message: 'Botões formatados',
        data: formattedButtons,
      }, null, 2));
      this.sendRequest();
    },

    getWhatsNumbers() {
      this.whatsNumbers = [];
      webApi.get('whatsapp/new/numbers').then(
        (response) => {
          console.log('WhatsNumbers', response.data);

          response.data.forEach((data) => {
            this.whatsNumbers.push(data);
          });

          const wId = this.whatsNumbers[0].phone_number;

          this.whatsapp_identification = wId;
          this.template.structure.business_id = wId;
        },
        (error) => {
          this.content = error;
          this.flowLoading = false;
        },
      );
    },
    changeNumber() {
      this.template.structure.business_id = this.whatsapp_identification;
    },
    callCrop(cropPreview, maxWidthP, maxHeightP, cropActive) {
      this.cropPreview = cropPreview;
      this.cropActive = cropActive;
      this.$root.$emit('show.modal', 'm-crop');
      this.$store.dispatch('crop/config', {
        maxWidth: maxWidthP,
        maxHeight: maxHeightP,
        viewMode: 1,
        aspectRatio: 1.91,
        minSize: true,
        infoSize: '1700x800',
      });
    },
    sendRequest() {
      const template = this.template;
      const form = JSON.parse(JSON.stringify(template.structure));
      const type = template.headerType;
      const buttons = this.formattedButtons();

      console.log('headerType', template.headerType);
      if (type) form.header = template.headers[type];

      if (template.footer.body) form.footer = template.footer;

      switch (template.structure.type) {
        case 'CATALOG':
          template.structure.buttons = {
            text: 'View catalog',
            type: 'CATALOG',
          };
          break;
        case 'MULT_PRODUCT':
          template.structure.buttons = {
            text: 'View items',
            type: 'MPM',
          };
          break;
        case 'COUPON_CODE':
          if (template.call_to_copy_code.example_code === '') {
            this.$toast.show({
              title: 'Erro',
              content: 'Você precisa preencher o código do cupom.',
              type: 'danger',
            });
            this.isSending = false;
            return;
          }
          form.buttons_json = {
            call_to_copy_code: {
              example_code: template.call_to_copy_code.example_code,
              text: template.call_to_copy_code.text,
            },
            type: 'COPY_CODE',
          };
          break;
        case 'OFFER':
          if (template.call_to_action_website.text === '') {
            this.$toast.show({
              title: 'Erro',
              content: 'Você precisa preencher o texto da url.',
              type: 'danger',
            });
            this.isSending = false;
            return;
          }
          if (template.call_to_action_website.url === '') {
            this.$toast.show({
              title: 'Erro',
              content: 'Você precisa preencher a url.',
              type: 'danger',
            });
            this.isSending = false;
            return;
          }
          if (template.call_to_copy_code.example_code === '') {
            this.$toast.show({
              title: 'Erro',
              content: 'Você precisa preencher o código do cupom.',
              type: 'danger',
            });
            this.isSending = false;
            return;
          }
          form.buttons_json = {
            call_to_copy_code: {
              example_code: template.call_to_copy_code.example_code,
              text: template.call_to_copy_code.text,
            },
            call_to_action_website: {
              url: template.call_to_action_website.url,
              text: template.call_to_action_website.text,
            },
            type: 'COPY_CODE',
          };
          form.limited = {
            text: 'Expiring offer!',
            has_expiration: template.limited.has_expiration,
          };
          break;
        case 'CAROUSEL':
          this.template.structure.carousel.content.cards.forEach((element) => {
            if (element.components[1].example.body_text[0].length === 0) {
              delete element.components[1].example;
            }
          });
          console.log('CAROUSEL', this.template.structure.carousel.content);
          form.carousel.content = JSON.stringify(
            this.template.structure.carousel.content,
          );
          break;
        default:
          break;
      }

      if (buttons.length > 0) {
        form.buttons_json = buttons;
      }

      if (this.whatsapp_identification === '') {
        this.$toast.show({
          title: 'Erro',
          content: 'Você precisa escolher um número antes e enviar.',
          type: 'danger',
        });
        return;
      }

      if (template.structure.category === 'AUTHENTICATION') {
        form.body.add_security_recommendation =
          template.structure.body.add_security_recommendation;
        form.footer.code_expiration_minutes =
          template.structure.footer.code_expiration_minutes;
        console.log(form);
        if (
          form.footer.code_expiration_minutes === undefined ||
          form.footer.code_expiration_minutes < 1
        ) {
          console.log('Veio aqui');
          form.footer = null;
        }
      }

      console.log('form', form);

      WhatsAppService.createNewTemplate(form, this.whatsapp_identification)
        .then((response) => {
          this.$root.$emit('template.submit');
          console.log('WhatsAppService.createNewTemplate:', response);
          this.$toast.show({
            title: this.$t('generic-str.success'),
            content: this.$t('template-component.created'),
            type: 'success',
          });
          this.$router.go(-1);
          this.isSending = false;
        })
        .catch((ex) => {
          this.isSending = false;
          this.$toast.show({
            title: `${this.$t('sms.infos.filters.status.lbl-error')}`,
            content: 'Erro interno, por favor, tente novamente',
            type: 'danger',
          });
          console.log(ex.response.data.errors);
        })
        .finally(() => {
          this.isSending = false;
        });
    },
    updateHeaderParams() {
      const header = this.template.headers.TEXT;

      let last = 1;

      header.body = header.body.replace(/\{\{[^\s]+?\}\}/g, (param) => {
        let newValue = '';

        param = param.slice(2, -2);

        if (last === 1) newValue = '{{1}}';

        last += 1;

        // console.log('updateHeaderParams', newValue);

        return newValue;
      });

      // console.log('updateHeaderParams', header.body);
    },
    updateBodyParams() {
      if (
        this.cardEditing.components === undefined &&
        this.template.structure.body.text
      ) {
        const body = this.template.structure.body;
        console.log('updateBodyParams', this.cardEditing.components);
        let last = 1;
        let max = 0;

        // if (body.text === this.lastBody) return;

        this.lastBody = body.text.replace(/\{\{[^\s]+?\}\}/g, (param) => {
          let newValue;

          param = param.slice(2, -2);

          if (param <= last && param !== '0') {
            newValue = param;
          } else {
            last += 1;
            newValue = last;
          }

          // if (param < last || param === '0') {
          //   newValue = last;
          // } else {
          //   newValue = param;
          // }

          // last += 1;

          if (newValue > max) max = parseInt(newValue, 10);

          return `{{${newValue}}}`;
        });

        body.text = this.lastBody;

        if (max && body.example_parameters.length !== max) {
          body.example_parameters = new Array(max);
        }
      }
    },
    updateBodyParamsCard() {
      if (this.cardEditing.components !== undefined) {
        const body = this.cardEditing.components[1];

        let last = 1;
        let max = 0;

        // if (body.text === this.lastBody) return;

        this.lastBodyCard = body.text.replace(/\{\{[^\s]+?\}\}/g, (param) => {
          let newValue;

          param = param.slice(2, -2);

          if (param <= last && param !== '0') {
            newValue = param;
          } else {
            last += 1;
            newValue = last;
          }

          // if (param < last || param === '0') {
          //   newValue = last;
          // } else {
          //   newValue = param;
          // }

          // last += 1;

          if (newValue > max) max = parseInt(newValue, 10);

          return `{{${newValue}}}`;
        });

        body.text = this.lastBodyCard;

        if (max && body.example.body_text[0].length !== max) {
          body.example.body_text[0] = new Array(max);
        }
      }
    },
  },
};
</script>

<style scoped lang="scss">
/* .header-type {
  max-width: 240px;
} */

.content::v-deep {
  .vti__dropdown {
    background: #fff;
  }
}

.category {
  color: #0d0d0d;

  .col-2 {
    text-align: center;

    &>i {
      font-size: 24pt;
    }
  }

  .col-10>h3,
  .col-2>i {
    color: inherit !important;
  }
}

.preview {
  border-radius: 0.2rem;
  background-color: #e5ddd5;
  box-sizing: border-box;
  height: 100%;
  padding: 15px;
  position: relative;

  &::before {
    background: url("/assets/img/chat-bg.png");
    background-size: 366px 666px;
    content: "";
    opacity: 0.06;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 0;
    width: 100%;
    height: 100%;
  }

  .preview-sample {
    max-width: 340px;
    position: sticky;
    top: 92px;
  }

  .preview-message,
  .preview-btns .btn {
    box-shadow: 0 1px 0.5px rgba(0, 0, 0, 0.15);
    background-color: #fff;
    border-radius: 0.32rem;
    width: 100%;
  }

  .preview-btns {
    margin: 0 !important;

    .btn {
      border: none;
      min-height: 32px;
      width: calc(50% - 0.25rem);
      margin: 0.25rem 0.25rem 0.5rem 0;

      &:nth-child(2) {
        margin: 0.25rem 0 0.5rem 0.25rem;
      }

      &.last {
        margin: 0.25rem 0 0.25rem 0;
        width: 100%;
      }
    }
  }

  .preview-message {
    border-top-left-radius: 0;
    padding: 0.25rem 0.25rem 0 0.25rem;
    position: relative;
    z-index: 2;
    min-height: 32px;

    &>header,
    &>div,
    &>footer {
      margin-bottom: 0.25rem;
      padding: 0.25rem;
    }

    &>time {
      position: absolute;
      bottom: 0.25rem;
      right: 0.5rem;
      font-size: 80%;
    }

    &>footer {
      color: rgb(140, 140, 140);
    }

    &>header {
      overflow: hiddden;
      max-height: 280px;
      max-height: 100%;
      font-weight: bold;

      .media {
        min-height: 100px;
        border-radius: 0.32rem;
        background: #ececec;
        margin-bottom: 0.2rem;
        overflow: hidden;

        &>img,
        &>object,
        &>video {
          object-fit: cover;
          width: 100%;
        }
      }
    }
  }
}

/* Rebranding */
.body-section {
  position: relative;
}

.input-info {
  position: absolute;
  right: 20px;
  top: unset;
  color: #aaa;
  bottom: -30px;
}

.input-info.text-area {
  top: 270px;
}

.new-template-whats {
  padding: 15px 15px 33px;
  margin: 0 auto;
  background: var(--background-2);
  margin: 10px;
}

.new-template-title {
  color: rgb(28, 30, 33);
}

.darkmode .new-template-title,
.darkmode .new-template-whats p span {
  color: #fff;
}

.new-template-whats p span {
  font-size: 14px;
  line-height: 18px;
  letter-spacing: normal;
  overflow-wrap: normal;
  text-align: left;
  color: rgb(96, 103, 112);
}

.carousel-visible {
  position: sticky;
  top: 100px;
}

.carousel-template .carousel-indicators li {
  width: 15px;
  height: 15px;
  background-color: var(--clr-yup-purple);
  border-radius: 50%;
}

.carousel-template::v-deep {
  .carousel-item[role="listitem"] {
    margin-bottom: 1.2rem !important;

    &>img {
      display: none !important;
    }
  }

  .carousel-caption {
    position: static !important;

    &>p {
      padding-left: 2rem;
      padding-right: 2rem;
    }
  }
}

.carousel-template::v-deep .carousel-indicators li {
  width: 15px;
  height: 15px;
  background-color: var(--clr-yup-purple);
  border-radius: 50%;
}

.carousel-template::v-deep .carousel-control-next-icon,
.carousel-template::v-deep .carousel-control-prev-icon {
  width: 100%;
  height: 45px;
  position: relative;
}

.carousel-template::v-deep .carousel-control-next-icon::after,
.carousel-template::v-deep .carousel-control-prev-icon::after {
  color: var(--clr-yup-purple);
  font-weight: 400;
  font-family: "Material Icons";
  right: 0;
  top: 10px;
  width: 100%;
}

.carousel-template::v-deep .carousel-control-next-icon::after {
  content: "\e5e1" !important;
}

.carousel-template::v-deep .carousel-control-prev-icon::after {
  content: "\e2ea";
}

.carousel-indicators {
  bottom: -1.5rem;
}

.icon-template span {
  color: var(--clr-yup-purple);
  font-size: 122px;
}

.new-template-whats {
  width: 50%;
  margin-left: auto;
  margin-right: auto;
}

.optional-template-input {
  display: block;
  float: left;
  font-size: 12px;
  background: #f4f4f4;
  padding: 5px;
  border-radius: 10px;
  transform: translateY(-8px) translateX(-5px) scale(0.8);
}

.darkmode .optional-template-input {
  background: var(--background-3);
}

.button-icon {
  height: fit-content;
  padding: 0;
}

.media-icon {
  height: fit-content;
  font-size: 1.75rem;
  transition: 0.5s;
  padding: 9px;
  cursor: pointer;
}

.media-icon.active {
  color: var(--clr-yup-purple);
}

.media-icon:hover {
  background-color: var(--background-icon-hover);
  border-radius: 5px;
}

.card-content-btn {
  background-color: white;
}

::v-deep .body-section .textarea-information {
  display: none;
}

::v-deep body {
  background-color: #fff;
}

.create-template-section {
  background: transparent;
  box-shadow: unset;
}

/* Phone preview */
.preview-div {
  display: flex;
  position: sticky;
  /* align-items: center; */
  height: fit-content;
  top: 100px;
}

.preview-wrapper {
  width: 100%;
  // height: fit-content; // Position-sticky
  // height: 512px;
  z-index: 1;
}

.phone-img {
  margin-left: auto;
  width: 20rem;
  height: fit-content;
}

.preview-btns .btn {
  color: var(--foreground);
}

.btn.last {
  width: 100%;
}

@media (min-width: 768px) {
  ::v-deep label {
    font-size: 1rem !important;
  }

  .phone-img {
    position: absolute;
    right: 0;
    padding-right: 3rem;
    // margin-top: 10%; // Position-sticky
    z-index: -1;
  }

  .preview-wrapper {
    /* position: sticky;
    top: 100px; */
    display: flex;
    align-items: center;
    height: 512px;
    flex-wrap: wrap;
  }

  .card-preview {
    // position: absolute; // Position-sticky
    position: relative;
    width: 410px;
    // top: 200px; // Position-sticky
    // right: 20%; // Position-sticky
    right: -20px;
    margin-left: auto;
    margin-bottom: 1rem;
    min-height: 93px;
    --gap: 0.5rem;
    background: #efe7dc;
    border: solid 1px #e0e0e0;
  }

  .card-preview .card-body {
    padding: 1rem 1rem 2rem;
    display: flex;
    flex-direction: column;

    video {
      max-width: 200px;
      margin-left: auto;
      margin-right: auto;
    }

    img,
    object {
      margin-left: auto;
      margin-right: auto;
    }

    .img-loading {
      width: 378px;
      height: 189px;
    }
  }
}

.rebranding .card.card-preview .card-text.footer-item {
  color: var(--gray-font-color);
  margin-bottom: 0;
}

.card-preview:first-of-type {
  margin-top: calc(10 * var(--gap));
}

.card-preview:last-of-type {
  margin-bottom: calc(35 * var(--gap));
}

.card-preview:last-of-type .card-body {
  padding: 1rem;
}

.preview-btns {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.sort-group,
.create-group {
  width: auto;
}

.card-btn-action {
  background-color: var(--background-3);
}

.card-btn-action2 {
  background-color: transparent;
  box-shadow: none;
}

.card-buttons-template {
  border: 2px solid #6b23dc3d;
  border-radius: 0.5rem;
}

/* btn Preview style */
.preview-message-btns {
  border-top: 1px solid #dadde1;
  margin-left: 5px;
  margin-right: 5px;
  margin-top: 10px;
  text-align: center;
  font-size: 14px;
  padding: 5px;
  color: #00a5f4;
  font-size: 14px;
  line-height: 20px;
}

.preview-message-btns i {
  color: #00a5f4;
}

.preview-message-btns span {
  display: block;
  margin-bottom: 8px;
}

@media (min-width: 768px) and (max-width: 1650px) {
  .card-preview {
    margin-right: auto;
  }
}

/* Refact */
.left-content-column {
  >h2 {
    font-size: 1rem;
    margin-bottom: 0;

    &~span {
      font-size: 0.75rem;
    }
  }

  .form-group {
    label {
      font-size: 1rem;

      >span {
        color: var(--gray-font-color-3);
      }
    }

    small {
      // margin-left: auto;
      text-align: right;
      color: var(--gray-font-color-3) !important;
    }
  }
}

.accordion-wpp-template {
  margin-top: 0.5rem;
  margin-bottom: 2rem;

  .card {
    background-color: #f9fafb;
    border-color: #e5e5e5;
    border-width: 1px !important;
    border-style: solid;
    box-shadow: none;
    margin-bottom: 1.25rem;

    .card-header {
      display: flex;
      height: auto;
      align-items: center;
      padding: 0.5rem 1rem;
      background-color: #f2f4f7;
      border-color: #e5e5e5;
    }
  }

  .btn-wrapper-opt {
    width: 100%;
    display: flex;
    height: fit-content;
    align-items: center;
    gap: 0.5rem;
    position: relative;
    z-index: 1;

    span.material-symbols-outlined {
      font-size: 45px;
      color: #667085;
      margin-right: 1rem;
    }

    .btn-template {
      display: flex;
      height: auto;
      padding: 0;
      font-weight: 400;

      .wrapper-title {
        display: flex;
        flex-direction: column;
        text-align: start;

        p.title-opt {
          font-size: 0.875rem;
          margin-bottom: 0.25rem;
        }

        span {
          white-space: break-spaces;
          font-size: 0.75rem;
          color: #595959;
        }
      }
    }

    .toggle-check {
      margin-left: auto;
      position: relative;
      z-index: 0;

      .control .control_indicator {
        order: 1;
        margin-left: auto;
        margin-right: 0;
        margin-bottom: 0;
      }
    }
  }
}

.header-wrapper {
  width: 100%;
  display: flex;
  gap: 1.25rem;

  .card {
    // background-color: #F9FAFB;
    border-color: #e5e5e5;
    border-width: 1px !important;
    border-style: solid;
    box-shadow: none;
    width: 107px;
    height: 147px;

    /* &.card:not(:first-child) .card-header {
      padding: .85rem .5rem;
    } */
    .card-header {
      height: 100%;
      padding: 0.85rem 0.5rem;
      transition: 0.5s;
      box-shadow: 0px 0px 0px 0px #ffffff00;

      img {
        width: 100%;
        height: 61px;
        object-fit: none;
        object-position: 50% 0px;
      }

      &.active {
        box-shadow: 0px 0px 0px 1px var(--clr-yup-purple);
      }
    }

    .icon-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #f2f2f2;
      width: 100%;
      height: 61px;

      span.material-symbols-outlined {
        color: var(--gray-font-color);
      }
    }

    .btn-choose-type {
      height: fit-content;
      padding: 0;
      font-weight: 400;
      font-size: 0.75rem;
    }
  }
}

.body-example-param {
  display: flex;
  flex-direction: column;

  >.badge {
    width: fit-content;
    margin-bottom: 0.5rem;
    margin-left: auto;
  }
}

.btn-wrapper {
  display: flex;
  align-items: center;
}

/* Preview */
.preview-wrapper {
  position: sticky;
  top: 90px;
}

.card-preview {
  padding: 10px;
  border-radius: 8px;
}

.card-preview .card {
  box-shadow: none !important;
  border-radius: 15px !important;
  overflow: hidden;
}

.card-preview .card-body {
  padding: 8px !important;
}

.card-preview .media {
  overflow: hidden;
  border-radius: 8px;
}

.type-row .card {
  flex: 1;
  margin: 3px;
}
</style>
<style scoped>
body {
  background-color: var(--background-3);
}

/* body.darkmode {
  background-color: #ffffff !important;
} */

.type-row span.material-symbols-outlined {
  font-size: 25px !important;
}

.btn-whats-message {
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  border-top-color: #e0e0e0 !important;
  border-top-width: 1px !important;
  border-top: solid;
}

.btn-whats-message a {
  display: block;
  justify-content: center;
  padding: 5px;
  color: #389dc9 !important;
  font-weight: 500;
  font-size: 15px;
  margin-right: 5px;
  margin-top: 3px;
}

.btn-whats-message a span {
  font-size: 15px;
  margin-top: 5px;
  margin-right: 5px;
}

.card-img {
  padding: 5px;
}

.card-img-top {
  border-radius: 10px;
}

.card-catalog .card-img {
  background: #f6f6f6;
  padding: 5px;
}

.card-catalog .prod-preview {
  display: flex;
  height: auto;
}

.prod-preview img {
  padding: 10px;
  width: 70px;
  background: #000;
}

.prod-preview h3 {
  font-size: 16px;
  margin: 0;
  margin-left: 10px;
}

.prod-preview p {
  color: #adadad;
  font-weight: 100;
  margin-left: 10px;
  margin-bottom: 0px;
}

.card-catalog .card-text {
  margin-bottom: 0px;
}

.footer-item {
  font-weight: 600;
  margin-bottom: 10px;
  padding-bottom: 0px;
}

.card-text {
  padding-bottom: 15px;
}

.time-span {
  position: absolute;
  right: 10px;
  color: #ccc;
  font-size: 13px;
  bottom: 5px;
}

.type-row .card-header.active {
  background: #752de6 !important;
}

.type-row .card-header:hover {
  background: #d0d0d0 !important;
}

.type-row .card-header.active button span {
  color: #fff !important;
}

.type-row .card-header.active button .wrapper-title p {
  color: #fff;
}

.offer-icon {
  width: 50px;
  height: 50px;
  background: #a0f7b3;
  border-radius: 50%;
  margin-left: 10px;
  justify-content: center;
  display: flex;
  align-items: center;
}

.offer-icon span {
  color: #47ad5e;
}

.offer-code {
  color: #c6c6c6;
}

.padding-b-0 {
  padding-bottom: 0px !important;
}

.padding-t-0 {
  padding-top: 0px !important;
}

.btn-whats-message-coupon a span {
  font-size: 15px !important;
  margin-top: 3px !important;
  margin-right: 5px;
}

.btn-whats-message-coupon a {
  font-size: 13px !important;
}

.h-160 {
  height: 160px !important;
}

.card-img-top {
  object-fit: cover;
  background: #000;
}

.video-header {
  width: 271px;
  height: 180px;
  background: #000;
}

.toggle-check .control .control_indicator {
  margin-right: 0px !important;
}

.edit_square_icon {
  font-size: 18px;
  margin: 8px;
}

.img-upload-icon {
  position: absolute;
  right: 30px;
  top: 15px;
}

.img-edit-icon {
  position: absolute;
  right: 30px;
  top: 45px;
}

.img-upload-icon span i {
  color: #fff;
}

::v-deep #sidebar-right-card-body {
  background-color: var(--background-2) !important;
  width: 525px !important;

  small {
    color: var(--gray-font-color);
  }

  .b-sidebar-header {
    padding-left: 1.5rem;
    padding-right: 1.5rem;

    button.close {
      margin-left: auto;
      margin-right: unset;
    }
  }

  .sidebar-header {
    display: flex;
    align-items: center;
    padding-top: 2.5rem;

    p {
      font-weight: 500;
      font-size: 1.25rem;
    }

    span {
      color: var(--gray-font-color-5);
    }

    span.sidebar-close {
      position: absolute;
      top: 12px;
      right: 0;
    }

    .arrow-back {
      position: absolute;
      top: 12px;
      left: -10px;
    }
  }

  .b-sidebar-body {
    padding: 0 1.5rem;
  }

  header {
    >strong {
      order: -1;
    }
  }

  .desc-column {
    p {
      color: var(--gray-font-color-4);
    }
  }

  .b-sidebar {
    width: 520px !important;
  }
}

.card-upload-btn {
  color: #fff;
  font-size: 18px;
  cursor: pointer;
}

.card-upload-btn:hover {
  color: #a4a4a4;
  font-size: 19px;
}

.edit-body-card-icon {}

.image-default-header {
  height: 150px;
  width: 100%;
  object-fit: cover;
}

.black-text {
  color: #000 !important;
}
</style>
